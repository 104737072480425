import { Icon, Typography, makeStyles } from "@material-ui/core";
import SmarthopNumberField from "@smarthop/form/fields/SmarthopNumberField";
import { createTooltip, formatNumber } from "app/main/utils/tableUtils";
import { useMemo } from "react";
import CostsBreakdownTooltip from "app/main/profile/cost-structure/calculator/CostBreakdownTooltip";
import EstimatePriceTooltip from "app/main/search/load/EstimatePriceTooltip";

const useStyles = makeStyles((theme) => ({
	rate: {
		maxWidth: "120px",
	},
}));

function LoadSearchSummaryRateView({ loadId, load, loading, permission, onUpdateRate, isPublicView = false }) {
	const classes = useStyles();

	const onChange = (newRate, forLoadId) => {
		if (loadId === forLoadId && newRate !== load?.price) {
			onUpdateRate?.(newRate);
		}
	};

	const renderDataItem = (label, value, type) => {
		return (
			<div className={"flex flex-row border-b-1 border-grey-300 "}>
				<Typography component={"div"} className="flex w-full text-black text-12 ml:text-13 break-normal mt-10">
					{label}
				</Typography>
				<Typography
					component={"div"}
					className={
						"text-12 ml:text-13  break-normal mt-10 font-semibold whitespace-nowrap " +
						(type === "BAD" ? " text-red-600 " : type === "GOOD" ? " text-green-600 " : " text-primary-700") +
						(loading ? " animate-pulse " : "  ")
					}
				>
					{!value ? "-" : value}
				</Typography>
			</div>
		);
	};

	const estRate = useMemo(
		() =>
			load?.est_price && load?.min_price && load?.max_price
				? `$${load?.est_price.toFixed(0)}`
				: load?.est_price && load?.min_price
				? `$${load?.min_price.toFixed(2)} - $${load?.est_price.toFixed(2)}`
				: "-",
		[load]
	);

	if (!permission.price) {
		return null;
	}

	return (
		<div className={"flex flex-col mb-10 -mt-10"}>
			{!isPublicView && (
				<div className={"flex flex-row border-b-1 border-grey-300 items-center justify-between mt-16"}>
					<Typography className="flex flex-1 font-semibold font-medium text-15">Rate</Typography>
					<SmarthopNumberField
						disabled={!!load?.deleted}
						name={load?.tripid}
						className={classes.rate}
						inputProps={{ className: " text-14 ml:text-2xl text-blue-700 text-right ", variant: "underline" }}
						field={{ noErrorMessage: true }}
						label={""}
						value={load?.price ?? 0}
						onChange={onChange}
						prefix={"$"}
					/>
				</div>
			)}
			{isPublicView && renderDataItem("Rate", !!load?.price ? "$" + formatNumber(load?.price) : "-")}
			{renderDataItem("RPM", !!load?.profitData?.rpm ? "$" + formatNumber(load?.profitData?.rpm) : "-")}
			{!isPublicView &&
				renderDataItem(
					<div className="flex flex-row">
						<Typography>Profit</Typography>
					</div>,
					createTooltip(
						<div className="flex flex-row">
							<Icon className={`text-13 ml-2 mt-3 text-${load?.profitData?.warnings?.length ? "red" : "black"} mr-2`}>
								{load?.profitData?.warnings?.length ? "warning" : "info"}
							</Icon>
							{load?.profitData?.profit >= 0 || load?.profitData?.profit < 0
								? "$" + formatNumber(load?.profitData?.profit)
								: "-"}
						</div>,
						<CostsBreakdownTooltip data={load?.profitData?.data} profitResults={load?.profitData} />
					),
					load?.profitData?.profit > 0 ? "GOOD" : load?.profitData?.profit < 0 ? "BAD" : null
				)}
			{!isPublicView &&
				renderDataItem(
					"Estimated Rate",
					createTooltip(
						<div className="flex flex-row">
							<Icon className="text-13 ml-2 mt-3 text-black mr-2">info</Icon>
							{estRate}
						</div>,
						<EstimatePriceTooltip load={load} />
					),
					"GOOD"
				)}
		</div>
	);
}

export default LoadSearchSummaryRateView;
